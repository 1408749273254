import React from "react";
import styled from "styled-components";
import TitleImage from "../images/enterOfTunnel.jpg";
import {useSetRecoilState} from "recoil";
import {SceneAtom} from "../GlobalState";
import useSound from "use-sound";
import buttonEffect from "../sound/Button.mp3";
import {CommandButton} from "./AdventureVer2";

const Credit = () => {

    const [playButtonEffect] = useSound(buttonEffect);
    const setScene = useSetRecoilState(SceneAtom);
    const backTitle = () => {
        playButtonEffect();
        setScene("Title");
    }

    return (
        <TitleView>
            <TitleText>素材引用サイト</TitleText>
            <LinkToMaterial href="http://www.ankokukoubou.com/sozai/sozaitobira.htm">画像　暗黒工房様</LinkToMaterial>
            <LinkToMaterial href="http://www.vita-chi.net/sozai1.htm">画像　びたちー素材館様</LinkToMaterial>
            <LinkToMaterial href="https://jp.freepik.com/free-vector/realistic-doors-collection-illustration_17806539.htm">著作者：pikisuperstar／出典：Freepik</LinkToMaterial>
            <LinkToMaterial href="https://maou.audio/">音楽　魔王魂様</LinkToMaterial>
            <LinkToMaterial href="https://nanamiyuki.com/">素材　七三ゆきのアトリエ様</LinkToMaterial>
            <LinkToMaterial href="https://everblasting.info/index.html">音楽　ぐらネタ様</LinkToMaterial>
            <CommandButton onClick={backTitle} style={{marginTop: "10px"}}>タイトルに戻る</CommandButton>
        </TitleView>
    );
}

export default Credit;

const TitleView = styled.div`
  text-align: center;
  background-image: url(${TitleImage});
  background-size: cover;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 500px) {
  }
`;

const TitleText = styled.div`
  color: aliceblue;
  font-size: 100px;

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 500px) {
    font-size: 50px;
  }
`;

const LinkToMaterial = styled.a`
  font-size: 34px;
  color: white;

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;
