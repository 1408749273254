import React from "react";
import styled from "styled-components";
import TitleImage from "../images/TitelBackGround.png";
import {useSetRecoilState} from "recoil";
import {SceneAtom} from "../GlobalState";
import TitleLogo from "../images/TitleLogo.png"
import useSound from "use-sound";
import buttonEffect from "../sound/Button.mp3";

const Title = () => {

    const [playButtonEffect] = useSound(buttonEffect);
    const setScene = useSetRecoilState(SceneAtom);

    const changeScene = () => {
        playButtonEffect()
        setScene("Adventure");
    }

    const goCredit = () => {
        playButtonEffect()
        setScene("Credit");
    }

    return (
        <TitleView>
            <TitleImg src={TitleLogo}/>
            <StartButton onClick={changeScene}>はじめる</StartButton>
            <GoCreditButton onClick={goCredit}>クレジット</GoCreditButton>
        </TitleView>
    );
}

export default Title;

const TitleView = styled.div`
  text-align: center;
  background-image: url(${TitleImage});
  background-size: cover;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {

  }

  @media screen and (max-width: 500px) {
    text-align: center;
    background-image: url(${TitleImage});
    background-size: cover;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const TitleImg = styled.img`

  @media screen and (max-width: 960px) {

  }
  
  @media screen and (max-width: 500px) {
   width: 90vw;
    }
`;

const StartButton = styled.div`
  border-style: solid;
  background-color: #000000;
  border-color: #ffffff;
  font-size: 34px;
  color: #ffffff;
  height: 50px;
  width: 200px;
  border-radius: 10px;
  margin-top: 20px;
  :hover {
    background-color: red;
    transition: 2s;
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {

  }

  @media screen and (max-width: 500px) {
    border-style: solid;
    background-color: #000000;
    border-color: #ffffff;
    font-size: 34px;
    color: #ffffff;
    height: 50px;
    width: 50vw;
    border-radius: 10px;
    margin-top: 20px;
    :hover {
      background-color: red;
      transition: 2s;
      cursor: pointer;
  }
`;

const GoCreditButton = styled.div`
  border-style: solid;
  background-color: #000000;
  border-color: #ffffff;
  font-size: 34px;
  color: #ffffff;
  height: 50px;
  width: 200px;
  border-radius: 10px;
  margin-top: 20px;
  :hover {
    background-color: red;
    transition: 2s;
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {

  }

  @media screen and (max-width: 500px) {
    border-style: solid;
    background-color: #000000;
    border-color: #ffffff;
    font-size: 34px;
    color: #ffffff;
    height: 50px;
    width: 50vw;
    border-radius: 10px;
    margin-top: 20px;
    :hover {
      background-color: red;
      transition: 2s;
      cursor: pointer;
    }
  }
`;







