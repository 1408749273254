import React from "react";
import styled from "styled-components";
import Title from "./Title";
import AdventureVer2 from "./AdventureVer2";
import {useRecoilState, useRecoilValue} from "recoil";
import EndTypeB from "./EndTypeB";
import MainFightView from "../fightingMode/MainFightView";
import {EventCount, PauseVisibilityState, Player, SceneAtom} from "../GlobalState";
import Credit from "./Credit";
import ReactModal from "react-modal";
import useSound from "use-sound";
import buttonEffect from "../sound/Button.mp3";

const SceneSelect = () => {
    const nowScene = useRecoilValue(SceneAtom)
    const [isVisible, setIsVisible] = useRecoilState(PauseVisibilityState);
    const [player] = useRecoilState(Player);
    const [playButtonEffect] = useSound(buttonEffect);
    const [eventCount] = useRecoilState(EventCount);

    const ClosePause = () => {
        playButtonEffect()
        setIsVisible(false);
    }

    const OpenPause = () => {
        playButtonEffect();
        setIsVisible(true);
    }

    const setChapter = () => {
        switch (nowScene) {
            case "Title" :
                return (<Title/>);
            case "Adventure":
                return (<AdventureVer2/>);
            case "EndTypeB":
                return (<EndTypeB/>);
            case "Fight":
                return (<MainFightView/>);
            case "ClearEnd":
                return (<EndTypeB/>);
            case "Credit":
                return (<Credit/>);
        }
    }

    return (
        <BackgroundView>
            <Pause isOpen={isVisible} style={PauseStyle}>
                <PlayerStatusWindow>
                    <PlayerStatusLog style={{marginTop: "100px"}}>{"攻撃力 : " + player.PlayerATK}</PlayerStatusLog>
                    <PlayerStatusLog>{"防御力 : " + player.PlayerDEF}</PlayerStatusLog>
                    <PlayerStatusLog>{"素早さ : " + player.PlayerAGI}</PlayerStatusLog>
                    <PlayerStatusLog>{"魔力 : " + player.MagicPower}</PlayerStatusLog>
                    <PlayerStatusLog>{"魔法残り : " + player.PlayerMagicCount}</PlayerStatusLog>
                    <PlayerStatusLog>{"包帯 " + player.PlayerPortion + " "}</PlayerStatusLog>
                    <PlayerStatusLog>{eventCount + "階"}</PlayerStatusLog>
                    <PauseCloseButton onClick={ClosePause}>ゲームに戻る</PauseCloseButton>
                </PlayerStatusWindow>
            </Pause>
            {!(nowScene === "Title" || nowScene === "Credit" || nowScene === "EndTypeB" || nowScene === "ClearEnd") && <PauseButton onClick={OpenPause}>Pause</PauseButton> }
            {setChapter()}

        </BackgroundView>
    );
}

export default SceneSelect;

const BackgroundView = styled.div`
  background-color: #282c34;
  width: 100%;
  max-width: 2000px;
  height: 100vh;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  

  @media screen and (max-width: 500px) {
    width: 100vw;
    max-width: 500px;
    height: 100vh;
  }
`;

const PlayerStatusWindow = styled.div`
  border-style: solid;
  border-color: white;
  background-color: black;
  width: 50%;
  height: 60%;
  margin: 10px auto 10px;
  text-align: center;

  @media screen and (max-width: 960px) {
    width: 50%;
    height: 70%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    height: 90%;
  }
`;

export const PlayerStatusLog = styled.div`
  font-size: 30px;
  color: orange;
  margin-top: 20px;
`;

export const PauseStyle = {
    overlay: {
        zIndex: "2",
    },
    content: {
        backgroundColor: "transparent",
        borderStyle: "none",
    }
}

export const Pause = ReactModal

export const PauseCloseButton = styled.button`
  border-style: solid;
  border-color: white;
  font-size: 30px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 10px;
  margin: 20px auto 10px;

  :hover {
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    font-size: 2.5vh;
  }

  @media screen and (max-width: 500px) {
    font-size: 3vh;
  }
`;

export const PauseButton = styled.div`
  border-style: solid;
  border-color: white;
  font-size: 30px;
  color: #ffffff;
  background-color: #000000;
  height: 50px;
  width: 150px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-right: -150px;
  bottom: 45%;
  left: 80%;

  :hover {
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    font-size: 2.5vh;
    height: 4.5vh;
    width: 15vw;
    margin-right: -15vw;
    left: 80%;
  }

  @media screen and (max-width: 500px) {
    font-size: 2.5vh;
    height: 4vh;
    width: 18vw;
    margin-right: -18vw;
    left: 80%;
  }
`;
