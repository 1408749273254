import React from "react";
import styled from "styled-components";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    EventCount,
    EventText,
    Player,
    SceneAtom
} from "../GlobalState";
import {SceneText} from "./TimeLine";
import GameOverSound from "../sound/Gameover.mp3";
import GameClearSound from "../sound/GameClearSound.mp3";
import GameOverImage from  "../images/GameOverImage.png";
import GameClearImage from "../images/GameClearImage.jpg";
import ReactHowler from "react-howler";
import {MainView} from "./AdventureVer2";

const EndTypeB = () => {
    const [playerStatus] = useRecoilState(Player);
    const resetPlayerStatus = useSetRecoilState(Player);
    const nowScene = useRecoilValue(SceneAtom)
    const setScene = useSetRecoilState(SceneAtom);
    const setEventText = useSetRecoilState(EventText);
    const [eventCount, setEventCount] = useRecoilState(EventCount);

    const setBGM = () => {
        if (nowScene === "EndTypeB") {
            return GameOverSound;
        } else {
            return GameClearSound;
        }
    }

    const setBackgroundImage = () => {
        if (nowScene === "EndTypeB") {
            return GameOverImage;
        } else {
            return GameClearImage;
        }
    }

    const setTextColor = () => {
        if (nowScene === "EndTypeB") {
            return "red";
        } else {
            return "yellow";
        }
    }

    const setBackgroundColor = () => {
        if (nowScene === "EndTypeB") {
            return "black";
        } else {
            return "white";
        }
    }

    const setButtonColor = () => {
        if (nowScene === "EndTypeB") {
            return "white";
        } else {
            return "orange";
        }
    }

    const setText = () => {
        if (nowScene === "EndTypeB") {
            return "GameOver";
        } else {
            return "Clear";
        }
    }

    const RestartGame = () => {
        resetPlayerStatus({...Player, MaxPlayerHP: 150, PlayerPortion: 0, PlayerDEF: 0, PlayerAGI: 3, PlayerATK: 10, PlayerHP: 150, HeelPower: 30, PlayerMagicCount: 5, MagicPower: 20})
        setEventText(SceneText.intro);
        setEventCount(1);
        setScene("Title");
    }

    return (
        <MainView backgroundImage={setBackgroundImage()} style={{justifyContent: "center"}}>
            <ReactHowler src={setBGM()} playing={true} loop={false} volume={0.5}/>
            <NormalEndingText style={{color: setTextColor(), fontSize: "10vw"}}>{setText()}</NormalEndingText>
            <NormalEndingText style={{color: setTextColor()}}>
                {setText()}時のステータス：
            </NormalEndingText>
            <NormalEndingText style={{color: setTextColor()}}>
                攻撃力:　{playerStatus.PlayerATK}
            </NormalEndingText>
            <NormalEndingText style={{color: setTextColor()}}>
                防御力:　{playerStatus.PlayerDEF}
            </NormalEndingText>
            <NormalEndingText style={{color: setTextColor()}}>
                素早さ:　{playerStatus.PlayerAGI}
            </NormalEndingText>
            {nowScene === "EndTypeB" &&
                <NormalEndingText style={{color: setTextColor()}}>
                    到達階数:　{eventCount}階
                </NormalEndingText>
            }
            <RestartButton onClick={RestartGame} style={{borderColor: "#ffffff", backgroundColor: setBackgroundColor(), color: setButtonColor()}}>タイトルへ</RestartButton>
        </MainView>
    );
}

export default EndTypeB;

const NormalEndingText = styled.div`
  font-size: 50px;
  margin-bottom: 10px;

  @media screen and (max-width: 960px) {
    font-size: 6vw;
  }

  @media screen and (max-width: 500px) {
  }
`;

const RestartButton = styled.button`
  width: 300px;
  height: 100px;
  font-size: 50px;
  border-style: dashed;

  @media screen and (max-width: 960px) {
    width: 250px;
    font-size: 40px;
    border-style: dashed;
  }

  @media screen and (max-width: 500px) {
    width: 150px;
    height: 50px;
    font-size: 20px;
  }
`;