import {atom} from "recoil";
import {SceneText} from "./adventureScene/TimeLine";
import {Unknown} from "./fightingMode/Enemys";

export const SceneAtom = atom<string>({
    key: "SceneAtom",
    default: "Title"
});

export const Player = atom({
    key: "player",
    default: {
        MaxPlayerHP: 150,
        PlayerHP: 150,
        PlayerATK: 10,
        PlayerAGI: 3,
        PlayerDEF: 0,
        PlayerPortion: 0,
        HeelPower: 30,
        MagicPower: 20,
        PlayerMagicCount: 5,
    }
})

export const EventCount = atom({
    key: "EventCount",
    default: 1
});

export const EventText = atom({
    key: "EventText",
    default: SceneText.intro
});

export const Enemy = atom({
    key: "enemy",
    default: Unknown
})

export const PauseVisibilityState = atom({
    key: "PauseVisibilityState",
    default: false,
})




