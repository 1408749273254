import React from 'react';
import {RecoilRoot} from "recoil";

import SceneSelect from "./adventureScene/SceneSelect";

function App() {

  return (
      <RecoilRoot>
          <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"/>
        <SceneSelect></SceneSelect>
      </RecoilRoot>
  );
}

export default App;
