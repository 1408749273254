import InsideOfTunnel from "../images/insideOfTunnel.jpg";
import InsideOfTunnel2 from "../images/insideOfTunnel2.jpg";
import InsideOfTunnel3 from "../images/InseideTunnel3.jpg";
import DarkForest from "../images/darkForest.jpg";
import DarkLoadInForest from "../images/darkLoadInForest.jpg";
import StartRoom from "../images/Room1.png";
import Cloth from "../images/cloth.png"
import Door from "../images/door.jpg"
import Ghost from "../images/yureib02r-removebg-preview.png";
import Snake from "../images/snake.png"
import BlackRoom from "../images/BlackRoom.jpg"
import FightBackGround from "../images/fightBackGround.png";
import WhiteRoom from "../images/whiteRoom.png";
import Ruins from  "../images/ruins.jpg"
import Church from "../images/church.png"
import Desert from "../images/desert.jpg"
import Bear from "../images/baer.png"
import UnknownMonster from "../images/unknown.png"
import Chicken from "../images/chicken.png";
import Panda from "../images/panda.png";
import WaterFall from "../images/waterFall.jpg"
import Priest from "../images/priest.png"
import Pastor from "../images/pastor.png"
import InTheFire from "../images/fireIntheHouse.png"
import OldMan from "../images/OldMan.png"
import ReinDeer from "../images/reindeer.png"
import sandStorm from "../images/sandStorm.png"
import Demon from "../images/Demon.png"
import Meadow from "../images/meadow.jpg"
import anotherChurch from "../images/church.jpg"
import wizard from "../images/wizard.png";
import treasureChest from "../images/treasureChest.png";
import room1 from "../images/whiteRoom.jpg"
import {Mimic} from "../fightingMode/Enemys";
import potion from "../images/Potion.png";
import bigPotion from "../images/BigPotion.png"
import azathothShadow from "../images/AzathothShadow.png";
import azathoth from "../images/Azathoth.png";



export const SceneText = {
    intro: [
        {
            text: "・・・",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "（ここは・・・？）",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "（家で寝ていたはず・・・）",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "周りをみるとどうやらここはコンクリートで作られた部屋のようだ",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "奥のほうに手紙と包帯、赤い液体の入った小瓶が目に入る",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "手紙にはこう書いてある",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "『あなたは今、夢の中にいます。",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "この夢から脱出するには、10時間夢の中で生き残ること",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "ただ同じ空間には1時間以上留まれません。",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "次の空間に繋がる扉を探し、制限時間内にその空間を脱出。",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "そして次の空間へ。その繰り返しです",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "扉の先がどこにつながるかは不規則です。何が起こるかわかりません",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "少しでも生還できるよう餞別をこの手紙と一緒においてあります",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "包帯は怪我の治療に、薬はあなたの能力を高めます",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "うまく活用してください。ではご武運を・・・』",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "この手紙が事実かわからないが、手紙を読んでいるうちに部屋が狭くなったのか、",
            image: Cloth,
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "天井が低くなっている。このままではつぶされてしまう",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "包帯を拾い、小瓶の中身を飲み干す",
            image: Cloth,
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "FirstPortionGave",
            soundEffect: ""
        },
        {
            text: null,
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "FirstStatusEvent",
            soundEffect: "StatusUp"
        },
        {
            text: "体から力がみなぎるのを感じる",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "振り返ると部屋の中央には扉がある。つぶされないためにも通るしかない",
            image: Door,
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "夢から脱出するためその扉を開け、先に進む",
            image: "",
            backgroundImage: StartRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventInTunnel: [
        {
            text: "扉を通るとそこはトンネルの中だった",
            image: "",
            backgroundImage: InsideOfTunnel,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "時間は限られている。次の扉を探さねば",
            image: "",
            backgroundImage: InsideOfTunnel,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: 'どちらに進む？\nA: 正面\n B: 後ろ',
            image: "",
            backgroundImage: InsideOfTunnel,
            branchType: "ChoiceInTheTunnel",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        }
    ],
    eventInTunnelA: [
        {
            text: "扉から出た方向に歩を進める",
            image: "",
            backgroundImage: InsideOfTunnel,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "トンネルの雰囲気が変わる。同時に奥から冷たい空気が流れ込む",
            image: "",
            backgroundImage: InsideOfTunnel2,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "外に出た。暗い森の中に扉が見える",
            image: Door,
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "しかし、近くを異形の何かが漂う",
            image: "",
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "どうやら気づかれてしまった。「それ」はこちらに向かってくる",
            image: Ghost,
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "「それ」は女の幽霊だった。幽霊は襲い掛かってくる！",
            image: Ghost,
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "Fight",
            soundEffect: ""
        },
    ],
    eventInTunnelB: [
        {
            text: "扉から出た方向と反対に進む",
            image: "",
            backgroundImage: InsideOfTunnel,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "最初は整っていた壁もボロボロになり、洞窟のようになる",
            image: "",
            backgroundImage: InsideOfTunnel3,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "外にでた。暗い森にでる。奥に光が見えたので、向かうと舗装された道路がある",
            image: "",
            backgroundImage: DarkLoadInForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "・・・しかし扉はない。後ろを振り向くと先ほどのトンネルは消えている",
            image: "",
            backgroundImage: DarkLoadInForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "必死になって扉を探し、走り回るが扉はない",
            image: "",
            backgroundImage: DarkLoadInForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "もう制限時間だろうか・・・そう思い絶望すると",
            image: "",
            backgroundImage: DarkLoadInForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "ミーツケタ",
            image: "",
            backgroundImage: DarkLoadInForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "背後から女の声がした・・・",
            image: "",
            backgroundImage: DarkLoadInForest,
            branchType: "",
            endType: "EndTypeB",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventInTunnelEnd: [
        {
            text: "戦いの末、女の幽霊を撃退する",
            image: "",
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "戦いで時間が経過したせいか、後ろからかすかにずるずると何かを引きずる音がする",
            image: "",
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "後ろを向くと遠くに白い大蛇がうごめいている",
            image: Snake,
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "気づかれる前にここを脱出しなければ",
            image: "",
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "扉を通り次の空間へ向かう",
            image: "",
            backgroundImage: DarkForest,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfAGIUp: [
        {
            text: "暗い部屋にでた",
            image: "",
            backgroundImage: BlackRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "パンダがヨガのポーズをとっている",
            image: Panda,
            backgroundImage: BlackRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "パンダに椅子に座らされ、足つぼマッサージを施術された",
            image: Panda,
            backgroundImage: BlackRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "ショックで昇天しそうな痛みだが、ダメージはない・・・",
            image: Panda,
            backgroundImage: BlackRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: Panda,
            backgroundImage: BlackRoom,
            branchType: "",
            endType: "",
            gameEvent: "AGIUpEvent",
            soundEffect: "StatusUp"
        },
        {
            text: "パンダに笹団子をつくってあげて、次の空間に向かう",
            image: Panda,
            backgroundImage: BlackRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfATKUp: [
        {
            text: "扉をあけると、白い空間にでた",
            image: "",
            backgroundImage: WhiteRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "中央にはマッスルな鶏がいる",
            image: Chicken,
            backgroundImage: WhiteRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "なぜか鶏と50分間筋トレをした",
            image: Chicken,
            backgroundImage: WhiteRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: Chicken,
            backgroundImage: WhiteRoom,
            branchType: "",
            endType: "",
            gameEvent: "ATKUpEvent",
            soundEffect: "StatusUp"
        },
        {
            text: "最後にプロテインを飲み交わして、次の空間へと向かった",
            image: Chicken,
            backgroundImage: WhiteRoom,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfDEFUp: [
        {
            text: "滝つぼにでた",
            image: "",
            backgroundImage: WaterFall,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "和尚さんが凄惨な形相で滝行をしてる",
            image: Priest,
            backgroundImage: WaterFall,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "和尚さんに捕まり、滝行をさせられる",
            image: Priest,
            backgroundImage: WaterFall,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: "",
            backgroundImage: WaterFall,
            branchType: "",
            endType: "",
            gameEvent: "DEFUpEvent",
            soundEffect: "StatusUp"
        },
        {
            text: "和尚とこたつで温まり、次の空間に向かう",
            image: Priest,
            backgroundImage: WaterFall,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfPortion: [
        {
            text: "教会らしき場所にでた",
            image: "",
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "ドット絵調の牧師がいる",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "「オー迷える子羊よー、包帯を捧げればー、奇跡がー起こるでしょうー」",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "どうしようか。包帯をA:あげる B:あげない",
            image: Pastor,
            backgroundImage: Church,
            branchType: "PortionGive",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfPortionA: [
        {
            text: "「イイ心がけデース。アナタに神の奇跡をー」",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "光が頭上から降り注ぐ・・・",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "HPHeelToMax",
            soundEffect: "ChurchHeel"
        },
        {
            text: "牧師に礼をいい、次の空間に向かう",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfPortionB: [
        {
            text: "「オー残念デース。無理強いはデキマセーン。」",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "残念そうにしてる牧師を残して、次の空間にむかう",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfPortionC: [
        {
            text: "包帯を渡そうとしたが、手持ちがなかった・・・",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "「オー残念デース。ない袖は振れマセーン。」",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "残念そうにしてる牧師を残して、次の空間にむかう",
            image: Pastor,
            backgroundImage: Church,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfCloseCall: [
        {
            text: "火事で燃え盛る建物の中にでた",
            image: "",
            backgroundImage: InTheFire,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "このままでは危ない、脱出しなければ！",
            image: "",
            backgroundImage: InTheFire,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "どっちに進む？ A: まっすぐ突っ切る　B:　左の部屋を通る",
            image: "",
            backgroundImage: InTheFire,
            branchType: "CloseCallBranch",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfCloseCallAfter: [
        {
            text: "どっちもたいして変わらない！",
            image: "",
            backgroundImage: InTheFire,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "10ダメージ受けた！",
            image: "",
            backgroundImage: InTheFire,
            branchType: "",
            endType: "",
            gameEvent: "InTheFireDamage",
            soundEffect: ""
        },
        {
            text: "どうにか脱出して、次の空間に向かう",
            image: "",
            backgroundImage: InTheFire,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfHelp: [
        {
            text: "暗い路地にでた",
            image: "",
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "誰かが路地の奥でたかられている！しかし手前にはドアがある。",
            image: "",
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "助ける？ A: 助ける　B: 見捨てる",
            image: "",
            backgroundImage: Ruins,
            branchType: "HelpOrAbandon",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfHelpA: [
        {
            text: "急いで助けに行き、金品をたかる蛮族と戦う",
            image: "",
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "Fight",
            soundEffect: ""
        },
    ],
    afterEventOfHelpA: [
        {
            text: "蛮族を追い払った。なんとたかられていたのは迷子のお爺さんだった",
            image: OldMan,
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: OldMan,
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "PortionGave",
            soundEffect: ""
        },
        {
            text: "その後お爺さんを探していたというトナカイと無事合流。別れを告げ、次の空間に向かう",
            image: ReinDeer,
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfHelpB: [
        {
            text: "お爺さんを見捨て、次の空間に向かう",
            image: "",
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "ドアを通る直前、再度路地の奥に目を向けるとトナカイが蛮族を叩きのめしていた",
            image: ReinDeer,
            backgroundImage: Ruins,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    eventOfBear: [
        {
            text: "砂漠にでた。熊がいる",
            image: Bear,
            backgroundImage: Desert,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "一緒にドアを探すさなか、途中のオアシスで休憩したときに熊にはちみつをもらった",
            image: Bear,
            backgroundImage: Desert,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: Bear,
            backgroundImage: Desert,
            branchType: "",
            endType: "",
            gameEvent: "HeelPowerUp",
            soundEffect: ""
        },
        {
            text: "お礼にオアシスでキングサーモンを釣ってあげた。別れを告げ次の空間に向かう",
            image: Bear,
            backgroundImage: Desert,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    magicPowerUp: [
        {
            text: "真っ白な聖堂にきた",
            image: "",
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "魔法使いがいる",
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "魔法使いは言う。「魔法の強化方法を選べ」",
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "どうしようか。魔法の A:撃てる回数を増やす B:一発の威力をあげる",
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "WizardEvent",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },

    ],
    magicPowerUpA: [
        {
            text: "魔法使いは呪文を唱えた",
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "magicCountUp",
            soundEffect: ""
        },
        {
            text: "魔法使いに礼をいい、次へ向かう",
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    magicPowerUpB: [
        {
            text: "魔法使いは呪文を唱えた",
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "magicPowerUp",
            soundEffect: ""
        },
        {
            text: "魔法使いに礼をいい、次へ向かう",
            image: wizard,
            backgroundImage: anotherChurch,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    mimicEvent: [
        {
            text: "真っ白な空間に来た",
            image: "",
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "真ん中に宝箱がある",
            image: treasureChest,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "他に何もないのであけてみた",
            image: Bear,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "randomChest",
            soundEffect: ""
        },
    ],
    mimicEventA: [
        {
            text: "開けようとしたら、一人でに動き出す",
            image: treasureChest,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "ミミックだ！",
            image: Mimic.enemyImage,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "口を大きく開け、ミミックが襲い掛かってくる！",
            image: Mimic.enemyImage,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "Fight",
            soundEffect: ""
        },
    ],
    mimicEventB: [
        {
            text: "中には虹色の液体が入った小瓶が入っている",
            image: potion,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "飲むと体に力がわいた",
            image: potion,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: potion,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "ExtraPotion",
            soundEffect: "StatusUp"
        },
        {
            text: "小瓶を捨て、次へ向かう",
            image: "",
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    mimicEventAfterFight: [
        {
            text: "どうにかミミックを倒した",
            image: "",
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "ミミックがいたところには虹色の液体が入った瓶がある",
            image: bigPotion,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "飲むと体に力がわいた",
            image: bigPotion,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: bigPotion,
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "ExtraPotionByMimic",
            soundEffect: "StatusUp"
        },
        {
            text: "瓶を捨て、次へ向かう",
            image: "",
            backgroundImage: room1,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    randomFight: [
        {
            text: "不気味な空間に出た",
            image: "",
            backgroundImage: FightBackGround,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "突如空間が歪み黒い影が襲い掛かる！",
            image: UnknownMonster,
            backgroundImage: FightBackGround,
            branchType: "",
            endType: "",
            gameEvent: "Fight",
            soundEffect: ""
        },
    ],
    randomFightEnd: [
        {
            text: "戦闘を終え、元の空間に戻る",
            image: "",
            backgroundImage: FightBackGround,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "いつのまにか出てきたドアを通り、次の空間に向かう",
            image: Door,
            backgroundImage: FightBackGround,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
    ],
    LastBossFight: [
        {
            text: "不気味な空間へとでた。しかし今までと雰囲気が違う",
            image: "",
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "真ん中には異形の化け物がいる",
            image: Demon,
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "こいつを倒さないと、脱出は無理そうだ",
            image: Demon,
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "夢からの脱出をかけて最後の戦いに挑む！",
            image: Demon,
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "Fight",
            soundEffect: ""
        },
    ],
    TrueLastBossFight: [
        {
            text: "倒したはずなのに、空間に変化がない・・・化け物も消えていない",
            image: Demon,
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: null,
            image: azathothShadow,
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "BossShadow",
            soundEffect: ""
        },
        {
            text: "こいつが異形の真の姿！",
            image: azathoth,
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "encounter",
            soundEffect: ""
        },
        {
            text: "異形は決して逃すまいと襲い掛かってくる！",
            image: azathoth,
            backgroundImage: sandStorm,
            branchType: "",
            endType: "",
            gameEvent: "Fight",
            soundEffect: ""
        },
    ],
    exitDream: [
        {
            text: "異形をどうにか退けた。いつの間にか周りは草原になっている",
            image: "",
            backgroundImage: Meadow,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "正面には夢からの出口だろう最後のドアがある",
            image: Door,
            backgroundImage: Meadow,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "どうやら脱出できそうだ。もう二度とこんな夢は見たくない",
            image: "",
            backgroundImage: Meadow,
            branchType: "",
            endType: "",
            gameEvent: "",
            soundEffect: ""
        },
        {
            text: "そう思いながらドアをあけた・・・",
            image: "",
            backgroundImage: Meadow,
            branchType: "",
            endType: "ClearEnd",
            gameEvent: "",
            soundEffect: ""
        },
    ],
}
