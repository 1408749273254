import Ghost from "../images/yureib02r-removebg-preview.png"
import Snake from "../images/snake.png"
import zombie from "../images/zonbie.png";
import bear from "../images/baer.png";
import bandit from "../images/bandit.png"
import Ruins from  "../images/ruins.jpg"
import Church from "../images/church.jpg"
import Meadow from "../images/meadow.jpg";
import Desert from "../images/desert.jpg"
import DarkForest from "../images/darkForest.jpg";
import bacteria from "../images/bacteria.png"
import dragon from "../images/Dragon.png"
import Demon from "../images/Demon.png"
import azathoth from "../images/Azathoth.png";
import leviathan from "../images/reviathan.png";
import bloodStorm from "../images/bloodStorm.png"
import inTheWater from "../images/intheWater.png"
import sandStorm from "../images/sandStorm.png"
import cell from "../images/Cell.png";
import cthulhu from "../images/cthulhu.png"
import nyarlathotep from "../images/Nyarlathotep.png"
import mimic from "../images/mimic.png"
import humanWolf from "../images/humanWolf.png"
import seaMan from "../images/seaMan.png";
import LastBossBGM from "../sound/LastBossBGM.mp3";
import FightBGM1 from "../sound/FightBGM1.mp3";
import FightBGM2 from "../sound/FightBGM2.mp3";
import FightBGM3 from "../sound/FightBGM3.mp3";
import FightBGM4 from "../sound/FightBGM4.mp3";
import FightBGM5 from "../sound/FightBGM5.mp3";
import FightBGM6 from "../sound/FightBGM6.mp3";
import FightBGM7 from "../sound/FightBGM7.mp3";
import FightBGM8 from "../sound/FightBGM8.mp3";
import TrueLassBossBGM from "../sound/TrueLassBossBGM.mp3"
import room1 from "../images/whiteRoom.jpg"



export const GhostOfLady = {
    enemyName: "女の幽霊",
    HP: 25,
    ATK: 4,
    DEF: 2,
    AGI: 3,
    enemyImage: Ghost,
    enemyBackGroundView: DarkForest,
    enemyBGM: FightBGM7,
};

export const BigSnake = {
    enemyName: "大蛇",
    HP: 30,
    ATK: 5,
    DEF: 3,
    AGI: 5,
    enemyImage: Snake,
    enemyBackGroundView: Desert,
    enemyBGM: FightBGM6,
};

export const Zombie = {
    enemyName: "ゾンビ",
    HP: 20,
    ATK: 5,
    DEF: 3,
    AGI: 1,
    enemyImage: zombie,
    enemyBackGroundView: Ruins,
    enemyBGM: FightBGM5,
};

export const Bear = {
    enemyName: "野生の熊",
    HP: 30,
    ATK: 5,
    DEF: 3,
    AGI: 6,
    enemyImage: bear,
    enemyBackGroundView: Meadow,
    enemyBGM: FightBGM8,
};

export const Bandit = {
    enemyName: "蛮族",
    HP: 20,
    ATK: 7,
    DEF: 2,
    AGI: 3,
    enemyImage: bandit,
    enemyBackGroundView: Ruins,
    enemyBGM: FightBGM3,
};

export const HumanWolf = {
    enemyName: "人狼",
    HP: 35,
    ATK: 6,
    DEF: 1,
    AGI: 7,
    enemyImage: humanWolf,
    enemyBackGroundView: Ruins,
    enemyBGM: FightBGM4,
}

export const Bacteria = {
    enemyName: "悪魔",
    HP: 80,
    ATK: 10,
    DEF: 4,
    AGI: 3,
    enemyImage: bacteria,
    enemyBackGroundView: cell,
    enemyBGM: FightBGM1,
}

export const DemonKing = {
    enemyName: "悪夢の祖",
    HP: 130,
    ATK: 14,
    DEF: 8,
    AGI: 10,
    enemyImage: Demon,
    enemyBackGroundView: sandStorm,
    enemyBGM: LastBossBGM,
}

export const Azathoth = {
    enemyName: "アザトース",
    HP: 250,
    ATK: 16,
    DEF: 6,
    AGI: 15,
    enemyImage: azathoth,
    enemyBackGroundView: sandStorm,
    enemyBGM: TrueLassBossBGM,
}

export const Leviathan = {
    enemyName: "リヴァイアサン",
    HP: 150,
    ATK: 12,
    DEF: 1,
    AGI: 8,
    enemyImage: leviathan,
    enemyBackGroundView: inTheWater,
    enemyBGM: FightBGM2,
}

export const Cthulhu = {
    enemyName: "クトゥルフ",
    HP: 150,
    ATK: 13,
    DEF: 9,
    AGI: 2,
    enemyImage: cthulhu,
    enemyBackGroundView: bloodStorm,
    enemyBGM: FightBGM2,
}

export const Nyarlathotep = {
    enemyName: "ニャルラトホテプ",
    HP: 120,
    ATK: 12,
    DEF: 0,
    AGI: 7,
    enemyImage: nyarlathotep,
    enemyBackGroundView: bloodStorm,
    enemyBGM: FightBGM1,
}

export const Mimic = {
    enemyName: "ミミック",
    HP: 10,
    ATK: 10,
    DEF: 15,
    AGI: 3,
    enemyImage: mimic,
    enemyBackGroundView: room1,
    enemyBGM: FightBGM3,
}

export const SeaMan = {
    enemyName: "海坊主",
    HP: 50,
    ATK: 11,
    DEF: 10,
    AGI: 1,
    enemyImage: seaMan,
    enemyBackGroundView: inTheWater,
    enemyBGM: FightBGM4,
}

export const Dragon = {
    enemyName: "ドラゴン",
    HP: 130,
    ATK: 11,
    DEF: 2,
    AGI: 3,
    enemyImage: dragon,
    enemyBackGroundView: bloodStorm,
    enemyBGM: FightBGM4,
}

export const Unknown = {
    enemyName: "蛮族",
    HP: 20,
    ATK: 7,
    DEF: 2,
    AGI: 3,
    enemyImage: bandit,
    enemyBackGroundView: Church,
    enemyBGM: LastBossBGM,
}

const enemyArray1 = [GhostOfLady, BigSnake, Zombie, Bear, Bandit, HumanWolf];

const enemyArray2 = [SeaMan, Bacteria, Dragon, Leviathan, Cthulhu, Nyarlathotep];


export const RandomEnemyFirstHalf = () => {
    const randomNumber = Math.floor(Math.random() * 119 + 1);
    const enemyNumber = randomNumber >= 100 ? 0 :
        randomNumber >= 80 && randomNumber < 100 ? 1
            : randomNumber >= 60 && randomNumber < 80 ? 2
                : randomNumber >= 40 && randomNumber < 60 ? 3
                    : randomNumber >= 20 && randomNumber < 40 ? 4 : 5;

    return enemyArray1[enemyNumber]
}

export const RandomEnemyLatterHalf = () => {
    const randomNumber = Math.floor(Math.random() * 599 + 1);
    const enemyNumber = randomNumber >= 501 ? 0 :
        randomNumber >= 401 && randomNumber < 501 ? 1
            : randomNumber >= 301 && randomNumber < 401 ? 2
                : randomNumber >= 201 && randomNumber < 301 ? 3
                    : randomNumber >= 101 && randomNumber < 201 ? 4 : 5;


    return enemyArray2[enemyNumber]
}

