import React, {useState} from "react";
import styled, {keyframes} from "styled-components";
import {useRecoilState, useSetRecoilState} from "recoil";
import {
    Enemy,
    EventCount,
    EventText,
    Player,
    SceneAtom
} from "../GlobalState";
import {SceneText} from "./TimeLine";
import useSound from "use-sound";
import buttonEffect from "../sound/Button.mp3";
import statusUpEffect from "../sound/statusUp.mp3";
import churchHeelSound from "../sound/ChurhHeel.mp3"
import AzathothSound from  "../sound/bull_azathoth.mp3"
import bombSound from "../sound/bomb.mp3"
import {RandomEnemyFirstHalf, RandomEnemyLatterHalf} from "../fightingMode/Enemys";
import {motion, useAnimation} from "framer-motion";

const AdventureVer2: React.FC = () => {
    const eventArray =
        [SceneText.eventInTunnel,
            SceneText.eventOfHelp,
            SceneText.eventOfCloseCall,
            SceneText.eventOfAGIUp,
            SceneText.eventOfATKUp,
            SceneText.eventOfDEFUp,
            SceneText.eventOfPortion,
            SceneText.eventOfBear,
            SceneText.magicPowerUp,
            SceneText.mimicEvent,
            SceneText.randomFight];

    const [eventCount, setEventCount] = useRecoilState(EventCount);
    const [playerStatus, setPlayerStatus] = useRecoilState(Player);
    const [playButtonEffect] = useSound(buttonEffect);
    const [playStatusUpEffect] = useSound(statusUpEffect);
    const [playChurchHeelSound] = useSound(churchHeelSound);
    const [azathothSound] = useSound(AzathothSound);
    const [BombSound] = useSound(bombSound);
    const [index, setIndex] = useState(0);
    const [selectedEvent, setSelectChapter] = useRecoilState(EventText);
    const [eventText, setEventText] = useState("");
    const NextEnemy = useSetRecoilState(Enemy);
    const mainWindowControls = useAnimation();
    const imageWindowControls = useAnimation();

    const setNextEventText = () => {
        if (selectedEvent[index].endType === "ClearEnd") {
            setScene("ClearEnd")
        } else if (selectedEvent[index].endType === "EndTypeB" || playerStatus.PlayerHP <= 0) {
            setScene("EndTypeB")
        } else if (selectedEvent[index].gameEvent === "Fight") {
            playButtonEffect()
            setScene("Fight")
        } else if (playerStatus.PlayerHP <= 0) {
            setScene("endTypeB")
        } else if (selectedEvent[index + 1] == null) {
            mainWindowControls.start({opacity: [0, 1]}, {duration: 1})
            setIndex(0);
            setNextChapter();
        } else {
            setIndex(index + 1);
        }


        selectEventText();
        selectSoundEffect();

    }

    const setRandomEventFirstHalf = () => {
        const randomNumber = (Math.floor(Math.random() * 700));
        const eventNumber = randomNumber < 100 ? 1 :
            randomNumber < 200 ? 3 :
                randomNumber < 300 ? 4 :
                    randomNumber < 400 ? 5 :
                        randomNumber < 500 ? 6 :
                          randomNumber < 600 ? 7 : 9;

        setSelectChapter(eventArray[eventNumber])
    }

    const setRandomEventLetterHalf = () => {
        const randomNumber = (Math.floor(Math.random() * 1250));
        const eventNumber = randomNumber < 125 ? 0 :
            randomNumber < 250 ? 1 :
                randomNumber < 375 ? 2 :
                    randomNumber < 500 ? 3 :
                        randomNumber < 625 ? 4 :
                            randomNumber < 750 ? 5 :
                                randomNumber < 875 ? 6 :
                                    randomNumber < 1000 ? 7 :
                                        randomNumber < 1125 ? 8 : 9;

        setSelectChapter(eventArray[eventNumber])
    }

    const setNextChapter = () => {
        if (eventCount + 1 === 20) {
            setSelectChapter(SceneText.LastBossFight);
            setEventCount(eventCount + 1);
        } else if (selectedEvent === SceneText.randomFightEnd) {
            if (eventCount + 1 >= 10) {
                playButtonEffect()
                setRandomEventLetterHalf()
                setEventCount(eventCount + 1);
            } else {
                playButtonEffect()
                setRandomEventFirstHalf()
                setEventCount(eventCount + 1);
            }
        } else {
            if (eventCount + 1 >= 10) {
                playButtonEffect()
                NextEnemy(RandomEnemyLatterHalf);
                setSelectChapter(SceneText.randomFight);
                setEventCount(eventCount + 1);
            } else {
                playButtonEffect()
                NextEnemy(RandomEnemyFirstHalf);
                setSelectChapter(SceneText.randomFight);
                setEventCount(eventCount + 1);
            }
        }
    }

    const setChapterA = () => {
        playButtonEffect()

        switch (selectedEvent[index].branchType) {
            case "ChoiceInTheTunnel" :
                setSelectChapter(SceneText.eventInTunnelA);
                setIndex(0);
                break;
            case "PortionGive" :
                if (playerStatus.PlayerPortion >= 1) {
                    setPlayerStatus({...playerStatus, PlayerPortion: playerStatus.PlayerPortion - 1});
                    setSelectChapter(SceneText.eventOfPortionA);
                    setIndex(0);
                } else {
                    setSelectChapter(SceneText.eventOfPortionC);
                    setIndex(0);
                }
                break;
            case "CloseCallBranch" :
                setSelectChapter(SceneText.eventOfCloseCallAfter);
                setIndex(0);
                break;
            case "HelpOrAbandon" :
                setSelectChapter(SceneText.eventOfHelpA)
                setIndex(0);
                break;
            case "WizardEvent" :
                setSelectChapter(SceneText.magicPowerUpA)
                setIndex(0);
                break;
        }
    }

    const setChapterB = () => {
        playButtonEffect()

        switch (selectedEvent[index].branchType) {
            case "ChoiceInTheTunnel" :
                setSelectChapter(SceneText.eventInTunnelB);
                setIndex(0);
                break;
            case "PortionGive" :
                setSelectChapter(SceneText.eventOfPortionB);
                setIndex(0);
                break;
            case "CloseCallBranch" :
                setSelectChapter(SceneText.eventOfCloseCallAfter);
                setIndex(0);
                break;
            case "HelpOrAbandon" :
                setSelectChapter(SceneText.eventOfHelpB)
                setIndex(0);
                break;
            case "WizardEvent" :
                setSelectChapter(SceneText.magicPowerUpB)
                setIndex(0);
                break;
        }
    }

    const setScene = useSetRecoilState(SceneAtom);

    const statusUpFirst = () => {

        const AGIDef = Math.floor(Math.random() * 4);
        const HPDef = Math.floor(Math.random() * 21);
        const DEFDef = Math.floor(Math.random() * 4);
        const ATKDef = Math.floor(Math.random() * 6);

        const calculateAGI = playerStatus.PlayerAGI + AGIDef;
        const calculateATK = playerStatus.PlayerATK + ATKDef;
        const calculateDEF = playerStatus.PlayerDEF + DEFDef;
        const calculateHP = playerStatus.PlayerHP + HPDef;

        setPlayerStatus({
            ...playerStatus, PlayerHP: calculateHP,
            MaxPlayerHP: calculateHP,
            PlayerAGI: calculateAGI,
            PlayerDEF: calculateDEF,
            PlayerATK: calculateATK
        })

        const AGIUpText = AGIDef >= 1 ? "素早さが" + AGIDef + "上がった。" : "";
        const HPUpText = HPDef >= 1 ? "HPが" + HPDef + "上がった。" : "";
        const DEFUpText = DEFDef >= 1 ? "防御力が" + DEFDef + "上がった。" : "";
        const ATKUpText = ATKDef >= 1 ? "攻撃力が" + ATKDef + "上がった。" : "";

        return HPUpText + ATKUpText + DEFUpText + AGIUpText;
    }

    const ATKUpEvent = () => {
        const afterTrainingATK = playerStatus.PlayerATK + 3;
        setPlayerStatus({...playerStatus, PlayerATK: afterTrainingATK});

        return "攻撃力が3上がった";
    }

    const AGIUpEvent = () => {
        const afterMassageAGI = playerStatus.PlayerAGI + 2;
        setPlayerStatus({...playerStatus, PlayerAGI: afterMassageAGI});

        return "体が軽くなり、素早さが2上がった";
    }

    const DEFUpEvent = () => {
        const afterDEF = playerStatus.PlayerDEF + 1;
        setPlayerStatus({...playerStatus, PlayerDEF: afterDEF});

        return "風邪をひいたが、我慢強くなった・・・防御力が1上がった";
    }

    const HeelEvent = () => {
        setPlayerStatus({...playerStatus, PlayerHP: playerStatus.MaxPlayerHP});
        return "HPが全回復した";
    }

    const inTheFireDamage = () => {
        const afterFire = playerStatus.PlayerHP - 10;
        BombSound()
        setPlayerStatus({...playerStatus, PlayerHP: afterFire <= 0 ? 0 : afterFire});
        mainWindowControls.start({x: [0, 10, -10, 10, -10, 0]})

        if (afterFire <= 0) {
            return "燃え盛る炎のなか力尽きてしまう"
        } else {
            return "燃え盛る炎で10ダメージくらってしまった"
        }
    }

    const HeelPowerUp = () => {
        const afterHeelPower = playerStatus.HeelPower + 10;
        setPlayerStatus({...playerStatus, HeelPower: afterHeelPower});
        return "包帯での回復量が10増えた！"
    }

    const PortionGave = () => {
        const afterPortions = playerStatus.PlayerPortion + 3;
        setPlayerStatus({...playerStatus, PlayerPortion: afterPortions});
        return "お爺さんは助けられたお礼にと包帯を3つくれた";
    }

    const MagicCountUp = () => {
        const afterMagicCount = playerStatus.PlayerMagicCount + 3;
        setPlayerStatus({...playerStatus, PlayerMagicCount: afterMagicCount});
        return "頭の中がクリアになり、魔法を打てる回数が3回分増えた";
    }

    const MagicPowerUp = () => {
        const afterMagicPower = playerStatus.MagicPower + 20;
        setPlayerStatus({...playerStatus, MagicPower: afterMagicPower});
        return "全身から力を感じ、魔力が20増えた";
    }

    const smallAllStatusUp = () => {
        const calculateAGI = playerStatus.PlayerAGI + 1;
        const calculateATK = playerStatus.PlayerATK + 1;
        const calculateDEF = playerStatus.PlayerDEF + 1;
        const calculateHP = playerStatus.PlayerHP + 10;
        const calculateMagicPower = playerStatus.MagicPower + 5;
        const calculateMaxHP = playerStatus.MaxPlayerHP + 10;

        setPlayerStatus({
            ...playerStatus, PlayerHP: calculateHP,
            MaxPlayerHP: calculateMaxHP,
            PlayerAGI: calculateAGI,
            PlayerDEF: calculateDEF,
            PlayerATK: calculateATK,
            MagicPower: calculateMagicPower
        })

        const HPUpText = "HPが" + 10 + "上がった。";
        const statusUpText = "素早さ、攻撃力、防御力が1上がった。"
        const MagicPowerText = "魔力が" + 5 + "上がった。";

        return HPUpText + statusUpText + MagicPowerText;
    }

    const AllStatusUp = () => {
        const calculateAGI = playerStatus.PlayerAGI + 3;
        const calculateATK = playerStatus.PlayerATK + 3;
        const calculateDEF = playerStatus.PlayerDEF + 2;
        const calculateHP = playerStatus.PlayerHP + 20;
        const calculateMagicPower = playerStatus.MagicPower + 10;
        const calculateMaxHP = playerStatus.MaxPlayerHP + 20;

        setPlayerStatus({
            ...playerStatus, PlayerHP: calculateHP,
            MaxPlayerHP: calculateMaxHP,
            PlayerAGI: calculateAGI,
            PlayerDEF: calculateDEF,
            PlayerATK: calculateATK,
            MagicPower: calculateMagicPower
        })

        const statusUpText = "素早さ、攻撃力が3、防御力が2上がった。"
        const HPUpText = "HPが" + 20 + "上がった。";
        const MagicPowerText = "魔力が" + 10 + "上がった。";

        return HPUpText + statusUpText + MagicPowerText;
    }

    const selectEventText = () => {
        switch (selectedEvent[index + 1].gameEvent) {
            case "FirstStatusEvent" :
                setEventText(statusUpFirst());
                break;
            case "ATKUpEvent" :
                setEventText(ATKUpEvent());
                break;
            case "AGIUpEvent" :
                setEventText(AGIUpEvent());
                break;
            case "DEFUpEvent" :
                setEventText(DEFUpEvent());
                break;
            case "HPHeelToMax" :
                setEventText(HeelEvent);
                break;
            case "InTheFireDamage" :
                setEventText(inTheFireDamage);
                break;
            case "HeelPowerUp" :
                setEventText(HeelPowerUp);
                break;
            case "FirstPortionGave" :
                setPlayerStatus({...playerStatus, PlayerPortion: 5});
                break;
            case "PortionGave" :
                setEventText(PortionGave);
                break;
            case "magicPowerUp" :
                setEventText(MagicPowerUp);
                break;
            case "magicCountUp" :
                setEventText(MagicCountUp);
                break;
            case "ExtraPotion" :
                setEventText(smallAllStatusUp);
                break;
            case "ExtraPotionByMimic" :
                setEventText(AllStatusUp);
                break;
            case "BossShadow" :
                setEventText("異形が黒い影に変わっていく・・・");
                imageWindowControls.start({opacity: [0, 1]}, {duration: 2})
                break;
            case "encounter" :
                azathothSound();
                imageWindowControls.start({x: [0, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 0]}, {duration: 2})
                break;
            case "randomChest" :
                const randomNumber = Math.floor(Math.random() * 10);
                if (randomNumber > 5) {
                    setSelectChapter(SceneText.mimicEventA);
                } else {
                    setSelectChapter(SceneText.mimicEventB);
                }
                setIndex(0);
                break;
        }
    }

    const selectSoundEffect = () => {
        switch (selectedEvent[index + 1].soundEffect) {
            case "StatusUp" :
                playStatusUpEffect()
                break;
            case "ChurchHeel" :
                playChurchHeelSound()
                break;
            default :
                playButtonEffect();
        }
    }

    const playerHPColor = () => {
        if (playerStatus.PlayerHP <= playerStatus.MaxPlayerHP / 5) {
            return "red"
        } else if (playerStatus.PlayerHP <= playerStatus.MaxPlayerHP / 2) {
            return "orange"
        } else {
            return "white"
        }
    }

    return (
        <MainView backgroundImage={selectedEvent[index].backgroundImage} animate={mainWindowControls}>
            <ImageWindow>
                {selectedEvent[index].image && <HumanImage src={selectedEvent[index].image} animate={imageWindowControls}/>}
            </ImageWindow>
            <WindowContents>
                <TextWindow>
                    <LogText>
                        {selectedEvent[index].text !== null && selectedEvent[index].text}
                        {selectedEvent[index].text === null && eventText}
                    </LogText>
                </TextWindow>
                <PlayerStatus>
                    <PlayerHPLog color="white" margin-right="5px">
                        {"HP"}
                    </PlayerHPLog>
                    <PlayerHPLog color={playerHPColor()}>
                        {playerStatus.PlayerHP}
                    </PlayerHPLog>
                    <PlayerHPLog color="white">
                        {"/"}
                    </PlayerHPLog>
                    <PlayerHPLog color="white">
                        {playerStatus.MaxPlayerHP}
                    </PlayerHPLog>
                    <PlayerHPLog color="white">
                        {eventCount + "階"}
                    </PlayerHPLog>
                </PlayerStatus>
                <DoButtons>
                    {selectedEvent[index].branchType && <CommandButton onClick={setChapterA} style={{marginRight: "10px", width: "15vw", paddingBottom: "7px", paddingTop: "7px"}}>A</CommandButton>}
                    {selectedEvent[index].branchType && <CommandButton onClick={setChapterB} style={{width: "15vw", paddingBottom: "7px", paddingTop: "7px"}}>B</CommandButton>}
                    {!selectedEvent[index].branchType &&
                        <CommandButton onClick={setNextEventText}>次へ</CommandButton>}
                </DoButtons>
            </WindowContents>
        </MainView>
    );
}

export default AdventureVer2;


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MainView = styled(motion.div)<{ backgroundImage: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 2s ease-in-out forwards;

  @media screen and (max-width: 960px) {
    background-position-x: 30%;
  }

  @media screen and (max-width: 500px) {
  }
`;


const ImageWindow = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 40px;
  display: flex;
  width: 900px;
  height: 550px;

  @media screen and (max-width: 960px) {
    height: 60%;
    width: 90vw;
  }

  @media screen and (max-width: 500px) {
    height: 60%;
    width: 90vw;
  }
`;

const HumanImage = styled(motion.img)`
  height: 100%;
  width: auto;
  margin: 0 auto 0;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 960px) {
    height: 80%;
    width: auto;
    margin: 20px auto 10px;
  }

  @media screen and (max-width: 500px) {
    height: 80%;
    margin: 50px auto 10px;
  }
`;

export const WindowContents = styled.div`
  width: 80%;
  max-width: 960px;
  padding: 20px;
  height: 350px;

  @media screen and (max-width: 960px) {
    width: 90vw;
    padding: 20px;
    height: 250px;
  }

  @media screen and (max-width: 500px) {
    height: 150px;
  }
`;

export const TextWindow = styled.div`
  overflow: auto;
  background-color: #000000;
  border-radius: 15px;
  border-style: solid;
  border-color: #ffffff;
  width: 910px;
  height: 200px;
  padding: 20px;
  text-align: justify;
  margin: 10px auto 10px;

  @media screen and (max-width: 960px) {
    width: 87vw;
    height: 150px;
    padding: 1vw;
    text-align: justify;
    margin: 0 0 1vh 0;
  }

  @media screen and (max-width: 500px) {
    width: 86vw;
    height: 80px;
    padding: 1vw;
  }
`;

export const LogText = styled.div`
  color: white;
  font-size: 40px;
  margin: 30px;

  @media screen and (max-width: 960px) {
    font-size: 2.5vh;
    margin: 10px;
  }

  @media screen and (max-width: 500px) {
    font-size: 4vw;
  }
`;

export const PlayerHPLog = styled.div`
  float: left;
  color: ${(props) => props.color};
  font-size: 35px;
  padding: 10px 0;
  margin-right: 5px;

  @media screen and (max-width: 960px) {
    font-size: 2.3vh;
    padding: 0.7vh 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 5vw;
  }
`;

export const PlayerStatus = styled.div`
  float: left;
  background-color: #000000;
  border-radius: 10px;
  border-style: solid;
  border-color: #ffffff;
  text-align: center;
  padding: 0 10px 0;

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

export const DoButtons = styled.div`
  float: right;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 960px) {
    
  }

  @media screen and (max-width: 500px) {
  }
`;


export const CommandButton = styled.button`
  border-style: solid;
  border-color: white;
  font-size: 50px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 10px;
  padding: 0 10px;

  :hover {
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    font-size: 3vh;
    margin-right: 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 6vw;
    margin-right: 0;
  }
`;
