import React, {useState} from "react";
import styled, {keyframes} from "styled-components";
import {useRecoilState, useSetRecoilState} from "recoil";
import ReactHowler from "react-howler";
import useSound from "use-sound";
import buttonEffect from "../sound/Button.mp3";
import PlayerAttackSound from "../sound/PlayerAttackSound.mp3"
import HeelSound from "../sound/heelSound.mp3"
import EnemyAttackSound from "../sound/EnemyAttackSound.mp3";
import DawnSound from "../sound/DawnSound.mp3"
import MissSound from "../sound/MissSound.mp3"
import slashEffect from "../images/slashEffect.png";
import magicEffect from "../images/magicEffect.png"
import criticalSlashEffect from "../images/critcalSlashEffect.png";
import PlayerCriticalSound from "../sound/CriticalSound.mp3";
import EnemyCriticalSound from "../sound/EnemyCriticalSound.mp3";
import bombSound from "../sound/bomb.mp3"
import victorySound from "../sound/VictoryShort.mp3"
import {motion, useAnimation} from "framer-motion";
import {Enemy, EventText, Player, SceneAtom} from "../GlobalState";
import {SceneText} from "../adventureScene/TimeLine";
import {Azathoth, Bandit, DemonKing, GhostOfLady, Mimic} from "./Enemys";
import {
    CommandButton,
    DoButtons,
    LogText,
    TextWindow,
    WindowContents
} from "../adventureScene/AdventureVer2";

const MainFightView: React.FC = () => {
    const [playButtonEffect] = useSound(buttonEffect);
    const [playerAttackSound] = useSound(PlayerAttackSound);
    const [heelSound] = useSound(HeelSound);
    const [enemyAttackSound] = useSound(EnemyAttackSound);
    const [dawnSound] = useSound(DawnSound);
    const [missSound] = useSound(MissSound);
    const [playerCriticalSound] = useSound(PlayerCriticalSound);
    const [enemyCriticalSound] = useSound(EnemyCriticalSound);
    const [playVictory] = useSound(victorySound);
    const [BombSound] = useSound(bombSound);
    const [playBGM, setPlayBGM] = useState(true);
    const [loopBGM, setLoopBGM] = useState(true);
    const [player, setPlayer] = useRecoilState(Player);
    const [selectChapterText, setSelectChapterText] = useRecoilState(EventText);
    const [FightEnemy] = useRecoilState(Enemy);
    const enemy = selectChapterText === SceneText.eventInTunnelA ? GhostOfLady :
                  selectChapterText === SceneText.LastBossFight ? DemonKing :
                  selectChapterText === SceneText.eventOfHelpA ? Bandit :
                  selectChapterText === SceneText.mimicEventA ? Mimic :
                  selectChapterText === SceneText.TrueLastBossFight ? Azathoth : FightEnemy;
    const [phase, setPhase] = useState({Phase: "CommandPhase"});
    const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    const judgeOrder = () => Math.floor(Math.random() * 10);
    const [enemyStatus, setEnemyStatus] = useState(enemy);
    const setScene = useSetRecoilState(SceneAtom);
    const judgeRegret = () => Math.floor(Math.random() * 100);
    const enemyActionNum = () => Math.floor(Math.random() * 100);
    const MessageSpeed = 1500;
    const judgeSatisfaction = () => Math.floor(Math.random() * 10);
    const imageControls = useAnimation();
    const enemyBarControls = useAnimation();
    const attackEffectControls = useAnimation();
    const magicEffectControls = useAnimation();
    const criticalAttackEffectControls = useAnimation();
    const attackedWindowControls = useAnimation();
    const [countTarns, setCountTarns] = useState(20);


    const move = (e: string) => {
        if (e === "heel" && player.PlayerHP === player.MaxPlayerHP) {
            Promise.resolve()
                .then(() => setLog("HPは満タンだ"))
                .then(() => wait(MessageSpeed))
                .then(() => setLog("どうしようか？"))
            return false;
        }

        const NextTarns = countTarns - 1;
        setCountTarns(NextTarns);

        playButtonEffect();
        setPhase({Phase: "MovePhase"});

        const playerStatus = {...player};
        const tempEnemy = {...enemyStatus};

        Promise.resolve(e)
            .then(() => wait(MessageSpeed))
            .then(() =>playerAction(e))
            .then(() => wait(MessageSpeed))
            .then(() => enemyAction(e))
            .then(() => wait(MessageSpeed))
            .then(() => restartTurn())
            .catch(function (e) {
                switch (e.message) {
                    case "enemy_dead" :
                        Promise.resolve(e)
                            .then(() => {
                                attackEffectControls.start({opacity: [1, 0], x: [1, 10]},)
                                playerAttackSound();
                                imageControls.start({x: [0, 25, -25, 0]}, {duration: 0.3})
                            })
                            .then(() => wait(MessageSpeed))
                            .then(winEnd)
                        break;
                    case "enemy_critical_dead" :
                        Promise.resolve(e)
                            .then(() => {
                                criticalAttackEffectControls.start({opacity: [1, 0], x: [1, 10]},)
                                playerCriticalSound();
                                imageControls.start({x: [0, 25, -25, 0], y: [0, 10, -10, 0]}, {duration: 0.3})
                            })
                            .then(() => wait(MessageSpeed))
                            .then(winEnd)
                        break;
                    case "enemy_magic_dead" :
                        Promise.resolve(e)
                            .then(() => {
                                magicEffectControls.start({opacity: [1, 0]},)
                                BombSound();
                                imageControls.start({x: [0, 25, -25, 0], y: [0, 10, -10, 0]}, {duration: 0.3})
                            })
                            .then(() => wait(MessageSpeed))
                            .then( winEnd)
                        break;
                    case "player_dead" :
                        Promise.resolve(e)
                            .then(() => {
                                attackedWindowControls.start({x: [0, 20, -20, 20, -20, 0]}, {duration: 1})
                                enemyAttackSound()
                                imageControls.start({scale: [1, 1.5, 1]}, )
                            })
                            .then(() => wait(MessageSpeed))
                            .then(() => {setLog("あなたは力尽きてしまった");
                                setLoopBGM(false);
                                setPlayBGM(false);
                                dawnSound()
                                setPhase({...phase, Phase: "CommandPhase"});
                                })
                        break;
                    case "player_critical_dead" :
                        Promise.resolve(e)
                            .then(() => {
                                attackedWindowControls.start({x: [0, 20, -20, 20, -20, 0], y: [0, 20, -20, 20, -20, 0]}, {duration: 1})
                                enemyCriticalSound()
                                imageControls.start({scale: [1, 1.5, 1]}, )
                            })
                            .then(() => wait(MessageSpeed))
                            .then(() => {setLog("あなたは力尽きてしまった");
                                setLoopBGM(false);
                                setPlayBGM(false);
                                dawnSound()
                                setPhase({...phase, Phase: "CommandPhase"});
                            })
                        break;
                }
            })

        const winEnd = () =>{
            Promise.resolve()
                .then(() => dawnSound())
                .then(() => wait(MessageSpeed))
                .then(() => {
                    if (enemy === Azathoth) {
                        setLog("あなたは最後の戦いに勝利した！");
                        setLoopBGM(false);
                        setPlayBGM(false);
                        setPhase({...phase, Phase: "CommandPhase"});
                        playVictory()
                        imageControls.start({opacity: 0})
                        enemyBarControls.start({opacity: 0})
                    } else {
                        setLog("あなたは戦いに勝利した。強化する能力値を選んでください");
                        setLoopBGM(false);
                        setPlayBGM(false);
                        setPhase({...phase, Phase: "selectStatusUp"});
                        if (enemy === DemonKing) {
                            enemyBarControls.start({opacity: 0})
                        } else {
                            playVictory()
                            imageControls.start({opacity: 0})
                            enemyBarControls.start({opacity: 0})
                        }
                    }
                })
        }

        const enemyAction = (e: string) => {
            if (enemyActionNum() < 90) {
                enemyAttack(e);
            } else {
                const waitAndSeeLog = enemy.enemyName + "はこちらをうかがっている";
                setLog(waitAndSeeLog);
            }
        }

        const enemyAttack = (e: string) => {

            const foundationDamage = enemy.ATK - player.PlayerDEF <= 0 ? 0 : enemy.ATK - player.PlayerDEF;

            const regretDamage = enemy.ATK * 3 - player.PlayerDEF <= 0 ? 0 : (enemy.ATK - player.PlayerDEF) * 3;

            if (e === "defence") {
                const defenceLog = foundationDamage <= 0 ? "あなたは身を守り攻撃を防いだ" : "あなたは身を守っている" + Math.floor((foundationDamage) / 2) + "ダメージを受けた";
                setLog(defenceLog)
                const finalDamage = foundationDamage <= 0 ? 0 : Math.floor((foundationDamage) / 2);
                const afterHP = playerStatus.PlayerHP - finalDamage;
                if (afterHP > 0) {
                    enemyAttackSound()
                    imageControls.start({scale: [1, 1.5, 1]}, )
                    playerStatus.PlayerHP = afterHP;
                    setPlayer(player => ({...player, PlayerHP: afterHP}))
                } else {
                    enemyAttackSound()
                    imageControls.start({scale: [1, 1.5, 1]},)
                    playerStatus.PlayerHP = 0;
                    setPlayer(player => ({...player, PlayerHP: 0}))
                    throw new Error("player_dead")
                }
            } else if (judgeEnemyHit()) {
                if (judgeRegret() < 10) {
                    const regretLog = regretDamage <= 0 ? "痛恨の一撃!!・・・しかし痛みはない" : "痛恨の一撃!!" + regretDamage + "ダメージを受けた";
                    setLog(regretLog);
                    const finalDamage = regretDamage <= 0 ? 0 : regretDamage;
                    const afterHP = playerStatus.PlayerHP - finalDamage;
                    if (afterHP > 0) {
                        attackedWindowControls.start({x: [0, 20, -20, 20, -20, 0], y: [0, 20, -20, 20, -20, 0]}, {duration: 1});
                        enemyCriticalSound()
                        imageControls.start({scale: [1, 1.5, 1]}, )
                        setPlayer(player => ({...player, PlayerHP: afterHP}))
                    } else {
                        setPlayer(player => ({...player, PlayerHP: 0}));
                        throw new Error("player_critical_dead")
                    }
                } else {
                    const foundationDamageLog = foundationDamage <= 0 ? "攻撃されたが痛みはない" : foundationDamage + "ダメージを受けた";
                    setLog(foundationDamageLog);
                    const finalDamage = foundationDamage <= 0 ? 0 : foundationDamage;
                    const afterHP = playerStatus.PlayerHP - finalDamage;
                    if (afterHP > 0) {
                        attackedWindowControls.start({x: [0, 20, -20, 20, -20, 0]}, {duration: 1});
                        enemyAttackSound()
                        imageControls.start({scale: [1, 1.5, 1]}, )
                        setPlayer(player => ({...player, PlayerHP: afterHP}));
                    } else {
                        setPlayer(player => ({...player, PlayerHP: 0}));
                        throw new Error("player_dead")
                    }
                }
            } else {
                missSound()
                imageControls.start({scale: [1, 1.5, 1], x: [0, 20, 0]}, )
                setLog("うまく攻撃をよけた");
            }
        }

        const restartTurn = () => {
            if (countTarns - 1 === 0) {
                setLog("戦闘が長引きすぎて、出口が消えてしまった・・・")
            }　else {
                setLog("次はどうしようか？");
            }
            setPhase({Phase: "CommandPhase"});
        }

        const playerAction = (e: string) => {
            if (e === "attack") {
                return doAttack();
            } else if (e === "heel") {
                return doHeel();
            }else if (e === "magic") {
                return doMagic();
            } else {
                setLog("あなたは身を固めている")
            }
        }

        const doAttack = () => {

            const foundationDamage = player.PlayerATK - enemy.DEF;

            const satisfactionDamage = (player.PlayerATK - enemy.DEF) * 3;

            if (judgePlayerHit()) {
                if (judgeSatisfaction() === 3) {
                    const satisfactionLog = satisfactionDamage <= 0 ? "会心の一撃！・・・しかし" + enemy.enemyName + "はダメージを受けた様子をみせない"
                        : "会心の一撃!!" + enemy.enemyName + "に" + satisfactionDamage + "ダメージを与えた";
                    setLog(satisfactionLog);
                    const finalDamage = satisfactionDamage <= 0 ? 0 : satisfactionDamage;
                    const afterHP = tempEnemy.HP - finalDamage;
                    if (afterHP > 0) {
                        criticalAttackEffectControls.start({opacity: [1, 0], x: [1, 10], },)
                        playerCriticalSound();
                        imageControls.start({x: [0, 25, -25, 0], y: [0, 10, -10, 0]}, {duration: 0.3})
                        tempEnemy.HP = afterHP;
                        setEnemyStatus(tempEnemy);
                    } else {
                        tempEnemy.HP = 0;
                        setEnemyStatus(tempEnemy);
                        throw new Error("enemy_critical_dead")
                    }
                } else {
                    const foundationLog = foundationDamage <= 0 ? enemy.enemyName + "に傷をつけることができなかった"
                        : enemy.enemyName + "に" + foundationDamage + "ダメージを与えた";
                    setLog(foundationLog);
                    const finalDamage = foundationDamage <= 0 ? 0 : foundationDamage;
                    const afterHP = tempEnemy.HP - finalDamage;
                    if (afterHP > 0) {
                        attackEffectControls.start({opacity: [1, 0], x: [1, 10], },)
                        playerAttackSound();
                        imageControls.start({x: [0, 25, -25, 0]}, {duration: 0.3})
                        tempEnemy.HP = afterHP;
                        setEnemyStatus(tempEnemy);
                    } else {
                        tempEnemy.HP = 0;
                        setEnemyStatus(tempEnemy);
                        throw new Error("enemy_dead")
                    }
                }
            } else {
                missSound()
                imageControls.start({x: [0, 50, 0]}, {duration: 0.5})
                setLog("攻撃が当たらなかった");
            }
        }

        const doMagic = () => {

            const magicDamage = player.MagicPower;
            playerStatus.PlayerMagicCount = playerStatus.PlayerMagicCount - 1;
            setPlayer(playerStatus);

            const magicLog = "魔法で" + enemy.enemyName + "に" + magicDamage + "ダメージを与えた";
            setLog(magicLog);
            const afterHP = tempEnemy.HP - magicDamage;
            if (afterHP > 0) {
                magicEffectControls.start({opacity: [1, 0]},) ;
                BombSound();
                imageControls.start({x: [0, 25, -25, 0]}, {duration: 0.3})
                tempEnemy.HP = afterHP;
                setEnemyStatus(tempEnemy);
            } else {
                tempEnemy.HP = 0;
                setEnemyStatus(tempEnemy);
                throw new Error("enemy_magic_dead")
            }
        }

        const doHeel = () => {

            const afterHeeledPlayerHP = player.PlayerHP + player.HeelPower;

            const fullHP = player.MaxPlayerHP;

            if (afterHeeledPlayerHP < fullHP) {
                playerStatus.PlayerHP = afterHeeledPlayerHP;
                playerStatus.PlayerPortion = playerStatus.PlayerPortion - 1;
                heelSound();
                setPlayer(playerStatus);
                const heelLog = "あなたは包帯で怪我の処置をした。HPを" + player.HeelPower +"回復"
                setLog(heelLog)
            } else {
                playerStatus.PlayerHP = playerStatus.MaxPlayerHP;
                playerStatus.PlayerPortion = playerStatus.PlayerPortion - 1;
                heelSound();
                setPlayer(playerStatus);
                setLog("あなたは包帯で怪我の処置をした。HPを満タンまで回復")
            }
        }

    }

    const judgePlayerHit = () => {
        if (player.PlayerAGI > enemy.AGI) {
            return Math.floor(Math.random() * 100) > 9;
        } else if (player.PlayerAGI < enemy.AGI) {
            return Math.floor(Math.random() * 100) > 20;
        } else if (enemy.AGI === player.PlayerAGI) {
            return judgeOrder() > 2;
        }
    }

    const judgeEnemyHit = () => {
        if (enemy.AGI > player.PlayerAGI) {
            return Math.floor(Math.random() * 100) > 9;
        } else if (enemy.AGI < player.PlayerAGI) {
            return Math.floor(Math.random() * 100) > 20;
        } else if (enemy.AGI === player.PlayerAGI) {
            return judgeOrder() > 2;
        }
    }

    const changeToAttackPhase = () => {
        playButtonEffect();
        setPhase({Phase: "selectAttackPhase"});
    }

    const changeToCommandPhase = () => {
        playButtonEffect();
        setPhase({Phase: "CommandPhase"});
    }

    const startFightLog = enemy.enemyName + "との戦いが始まった。戦闘可能時間、残り" + countTarns +　"ターン。どうする？";

    const [Log, setLog] = useState(startFightLog);

    const playLog = () => {
        return Log;
    }

    const playerHPColor = () => {
        if (player.PlayerHP <= player.MaxPlayerHP / 5) {
            return "red"
        } else if (player.PlayerHP <= player.MaxPlayerHP / 2) {
            return "orange"
        } else {
            return "white"
        }
    }

    const tarnColor = () => {
        if (countTarns <= 3) {
            return "red"
        } else {
            return "white"
        }
    }

    const enemyHPColor = () => {
        if (enemyStatus.HP <= enemy.HP / 5) {
            return "red"
        } else if (enemyStatus.HP <= enemy.HP / 2) {
            return "orange"
        } else {
            return "green"
        }
    }

    const ATKUp = () => {
        const afterATK = player.PlayerATK + 1;
        setPlayer({...player, PlayerATK: afterATK});
        setLog("攻撃力を1あげた");
        heelSound()
        setPhase({...phase, Phase: "CommandPhase"});
    }

    const AGIUp = () => {
        const afterAGI = player.PlayerAGI + 1;
        setPlayer({...player, PlayerAGI: afterAGI});
        setLog("素早さを1あげた");
        heelSound()
        setPhase({...phase, Phase: "CommandPhase"});
    }

    const DEFUp = () => {
        const afterDEF = player.PlayerDEF + 1;
        setPlayer({...player, PlayerDEF: afterDEF});
        setLog("防御力を1あげた");
        heelSound()
        setPhase({...phase, Phase: "CommandPhase"});
    }

    const endFight = () => {
        playButtonEffect()
        switch (selectChapterText) {
            case SceneText.eventInTunnelA :
                setSelectChapterText(SceneText.eventInTunnelEnd)
                setScene("Adventure")
                break;
            case SceneText.eventOfHelpA :
                setSelectChapterText(SceneText.afterEventOfHelpA)
                setScene("Adventure")
                break;
            case SceneText.LastBossFight :
                setSelectChapterText(SceneText.TrueLastBossFight)
                setScene("Adventure")
                break;
            case SceneText.TrueLastBossFight :
                setSelectChapterText(SceneText.exitDream)
                setScene("Adventure")
                break;
            case SceneText.mimicEventA :
                setSelectChapterText(SceneText.mimicEventAfterFight)
                setScene("Adventure")
                break;
            default :
                setSelectChapterText(SceneText.randomFightEnd);
                setScene("Adventure")
        }
    }

    const loseFight = () => {
        playButtonEffect()
        setScene("EndTypeB");
    }

    const judgeResult = () => {

        if (countTarns === 0 && phase.Phase === "CommandPhase") {
            return (
                <DoButtons>
                    <CommandButton onClick={loseFight}>次へ</CommandButton>
                </DoButtons>
            );
        } else 　if (enemyStatus.HP <= 0 && phase.Phase === "CommandPhase") {
            return (
                <DoButtons>
                    <CommandButton onClick={endFight}>次へ</CommandButton>
                </DoButtons>
            );
        } else if (player.PlayerHP <= 0 && phase.Phase === "CommandPhase") {
            return (
                <DoButtons>
                    <CommandButton onClick={loseFight}>次へ</CommandButton>
                </DoButtons>
            );
        } else if (phase.Phase === "CommandPhase") {
            return (
                <DoButtons style={{margin: "auto"}}>
                    <CommandButtonInFight onClick={changeToAttackPhase} style={{marginRight: "10px"}}>戦う</CommandButtonInFight>
                    <CommandButtonInFight onClick={() => move("defence")} style={{marginRight: "10px"}}>防御</CommandButtonInFight>
                    {player.PlayerPortion !== 0 && <CommandButtonInFight onClick={() => move("heel")}>回復</CommandButtonInFight>}
                </DoButtons>
            );
        } else if (phase.Phase === "selectAttackPhase") {
            return (
                <DoButtons style={{margin: "auto"}}>
                    <CommandButtonInFight onClick={() => move("attack")} style={{marginRight: "10px"}}>攻撃</CommandButtonInFight>
                    {player.PlayerMagicCount !== 0 && <CommandButtonInFight onClick={() => move("magic")} style={{marginRight: "10px"}}>魔法</CommandButtonInFight>}
                    <CommandButtonInFight onClick={changeToCommandPhase} style={{marginRight: "0"}}>戻る</CommandButtonInFight>
                </DoButtons>
            );
        } else if (phase.Phase === "selectStatusUp") {
            return (
                <DoButtons style={{margin: "auto"}}>
                    <CommandButtonInFight onClick={ATKUp} style={{marginRight: "10px"}}>攻撃力</CommandButtonInFight>
                    <CommandButtonInFight onClick={DEFUp} style={{marginRight: "10px"}}>防御力</CommandButtonInFight>
                    <CommandButtonInFight onClick={AGIUp} style={{marginRight: "0"}}>素早さ</CommandButtonInFight>
                </DoButtons>
            );
        }  else {
            return false;
        }
    }

    return (
        <FightingView backgroundImage={enemy.enemyBackGroundView} animate={attackedWindowControls}>
            <ReactHowler src={enemy.enemyBGM} playing={playBGM} loop={loopBGM} volume={0.5}/>
            <EnemyWindow>
                <EnemyHPBarCover width={enemy.HP * 5 + "px"} animate={enemyBarControls}>
                    <EnemyHPBar width={(enemyStatus.HP / enemy.HP) * 100 + "%"} backgroundColor={enemyHPColor()}/>
                </EnemyHPBarCover>
                <EffectImage src={criticalSlashEffect} initial={{opacity: 0}} animate={criticalAttackEffectControls}/>
                <EffectImage src={slashEffect} initial={{opacity: 0}} animate={attackEffectControls}/>
                <EffectImage src={magicEffect} initial={{opacity: 0, scale: 2}} animate={magicEffectControls}/>
                <HumanImage src={enemy.enemyImage} animate={imageControls}/>
            </EnemyWindow>
            <WindowContents >
                <TextWindow>
                    <LogText>
                        {playLog()}
                    </LogText>
                </TextWindow>
                <PlayerStatusInFight style={{margin: "0 0 10px 0", }}>
                    <PlayerHPLogInFight color="white" margin-right="5px">
                        {"HP"}
                    </PlayerHPLogInFight>
                    <PlayerHPLogInFight color={playerHPColor()}>
                        {player.PlayerHP}
                    </PlayerHPLogInFight>
                    <PlayerHPLogInFight color="white">
                        {"/"}
                    </PlayerHPLogInFight>
                    <PlayerHPLogInFight color="white">
                        {player.MaxPlayerHP}
                    </PlayerHPLogInFight>
                    {phase.Phase !== "selectStatusUp" &&
                        <PlayerHPLogInFight color={tarnColor()}>
                            {"残り" + countTarns + "ターン"}
                        </PlayerHPLogInFight>
                    }
                </PlayerStatusInFight>
                {judgeResult()}
            </WindowContents>
        </FightingView>
    );
}
export default MainFightView;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FightingView = styled(motion.div)<{ backgroundImage: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 2s ease-in-out forwards;

  @media screen and (max-width: 960px) {
    background-position-x: 50%;
  }

  @media screen and (max-width: 500px) {
  }
`;

const EnemyWindow = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 40px;
  display: flex;
  width: 900px;
  height: 550px;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 960px) {
    margin-top: 30px;
    width: 90vw;
    height: 50%;
  }

  @media screen and (max-width: 500px) {
    height: 60%;
  }
`;

const HumanImage = styled(motion.img)`
  position: relative;
  z-index: 1;
  height: 80%;
  width: auto;
  margin: 10px auto 10px;

  @media screen and (max-width: 960px) {
    height: 90%;
    margin: 10px auto 0;
  }

  @media screen and (max-width: 500px) {
    height: 85%;
    margin: 10px auto 0;
  }
`;

const EffectImage = styled(motion.img)`
  margin: 0 auto -300px;
  position: relative;
  z-index: 2;
  top: 30%;
  height: 300px;
  width: auto;

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 500px) {
    margin-bottom: -100px;
    height: 100px;
  }
`;

const EnemyHPBarCover = styled(motion.div)<{width : string}>`
  height: 30px;
  width: ${(props) => props.width};
  max-width: 95%;
  border-style: solid;
  border-color: white;
  border-radius: 5px;
  background-color: black;

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 500px) {
    height: 20px;
    margin-bottom: 10px;
  }
`;

const EnemyHPBar = styled.div<{width : string,backgroundColor : string}>`
    height: 30px;
  width: ${(props) => props.width};
  max-width: 100%;
  background-color: ${(props) => props.backgroundColor};

  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 500px) {
    height: 20px;
  }
`;

export const CommandButtonInFight = styled.button`
  border-style: solid;
  border-color: white;
  font-size: 50px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 10px;
  padding: 0 10px;

  :hover {
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    font-size: 6vw;
    margin-right: 0;
    width: 29vw;
  }

  @media screen and (max-width: 500px) {
    font-size: 6vw;
    margin-right: 0;
    width: 28vw;
  }
`;

const PlayerHPLogInFight = styled.div`
  float: left;
  color: ${(props) => props.color};
  font-size: 35px;
  padding: 10px 0;
  margin-right: 5px;

  @media screen and (max-width: 960px) {
    font-size: 5vw;
    padding: 0.7vh 0;
  }

  @media screen and (max-width: 500px) {
    font-size: 5vw;
  }
`;

export const PlayerStatusInFight = styled.div`
  float: left;
  background-color: #000000;
  border-radius: 10px;
  border-style: solid;
  border-color: #ffffff;
  text-align: center;
  padding: 0 10px 0;

  @media screen and (max-width: 960px) {
    margin-bottom: 10px;
    width: 86vw;
    height: 10vw;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
    width: 83.5vw;
    height: 10vw;
  }
`;
